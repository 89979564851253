<template>
    <layout>
        <div class="container pt-4 d-print-none mb-5">
            <h1 class="page-title text-primary mb-5 text-uppercase">Payments Statement</h1>
            <div class="card mb-4">
                <div class="card-body">
                    <div class="row">
                        <div class="col-md-3 mb-3 mb-md-0">
                            <label for="dateRange" class="form-label">Date range</label>
                            <select class="form-select" v-model="dateRangeType" id="dateRange">
                                <option value="0" selected>- Select -</option>
                                <option v-for="item in dateRange" :value="item.id" :key="item.id">{{ item.value }}</option>
                            </select>
                        </div>
                        <div class="col-md-3 mb-3 mb-md-0">
                            <label for="fromDate" class="form-label">From</label>
                            <div class="input-group">
                                <span class="input-group-text text-black-50 calendar-icon"><i class="fas fa-calendar-day"></i></span>
                                <input type="text" class="form-control datePicker datePicker-input" id="fromDate" placeholder="" />
                            </div>
                        </div>
                        <div class="col-md-3">
                            <label for="toDate" class="form-label">To</label>
                            <div class="input-group">
                                <span class="input-group-text text-black-50 calendar-icon"><i class="fas fa-calendar-day"></i></span>
                                <input type="text" class="form-control datePicker datePicker-input" id="toDate" placeholder="" />
                            </div>
                        </div>
                    </div>
                    <div v-if="!isAgent">
                        <div class="row mt-4">
                            <div class="col-md-3">
                                <label class="form-label">Group By</label>
                                <select class="form-select" v-model="groupBy" @change="onChangeGroupBy">
                                    <option v-for="item in groupByData" :key="item.value" :value="item.value">{{ item.text }}</option>
                                </select>
                            </div>
                            <div class="col-md-6">
                                <label class="form-label">Select a Company</label>
                                <v-select :options="companies" :clearable="false" :reduce="(item) => item.id" label="value" v-model="companyId"></v-select>
                            </div>
                        </div>
                        <ul class="list-unstyle list-inline m-0 mt-4">
                            <li class="list-inline-item pe-3">
                                <div class="form-check">
                                    <input class="form-check-input" v-model="creditCardPaymentsOnly" type="checkbox" value="1" id="checkCrediCard" />
                                    <label class="form-check-label" for="checkCrediCard">Credit Card Payments Only</label>
                                </div>
                            </li>
                            <li class="list-inline-item pe-3">
                                <div class="form-check">
                                    <input class="form-check-input" v-model="ACHPaymentsOnly" type="checkbox" value="1" id="checkACH" />
                                    <label class="form-check-label" for="checkACH">ACH Payments Only</label>
                                </div>
                            </li>
                            <li class="list-inline-item">
                                <div class="form-check">
                                    <input class="form-check-input" type="checkbox" value="" id="checkAccountGroup" />
                                    <label class="form-check-label" for="checkAccountGroup">Group by "Accounting Group"</label>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="d-flex">
                <div>
                    <button type="button" @click="displayData" class="btn btn-primary text-uppercase" :disabled="loading"><i class="fas fa-search"></i> {{ loading ? 'Wait...' : 'Display' }}</button>
                    <button type="button" @click="exportData" class="btn btn-primary text-uppercase ms-3" :disabled="loading" v-show="false"><i class="fas fa-download"></i> <span v-show="!loading">Export</span><span v-show="loading">Waiting...</span></button>
                </div>
                <button type="button" class="btn btn-outline-primary text-uppercase ms-auto" @click="print" :disabled="!data"><i class="fas fa-print"></i> Print</button>
            </div>
        </div>
        <loading :show="loading" />
        <div v-if="data && !loading">
            <div class="container mb-4">
                <div class="row d-none d-print-flex">
                    <div class="col-6 text-start">
                        <img src="@/assets/img/inter-co-logo.svg" class="logo" alt="Inter Bank" />
                    </div>
                    <div class="col-6 text-end d-flex">
                        <h1 class="fs-4 mb-0 mt-auto ms-auto">Payments Statement</h1>
                    </div>
                </div>
                <hr class="d-none d-print-block" />
                <div class="row">
                    <div class="col-6">
                        <div class="row mb-2">
                            <div class="col-6 text-end"><label class="fw-bold text-uppercase">Statement Period</label></div>
                        </div>
                        <div class="row mb-2">
                            <div class="col-6 text-end"><label class="fw-bold">From:</label></div>
                            <div class="col-6">{{ data.statetementPeriodFrom }}</div>
                        </div>
                        <div class="row mb-2">
                            <div class="col-6 text-end"><label class="fw-bold">To:</label></div>
                            <div class="col-6">{{ data.statetementPeriodTo }}</div>
                        </div>
                        <div class="row mb-2">
                            <div class="col-6 text-end"><label class="fw-bold text-uppercase">Date of Statement:</label></div>
                            <div class="col-6">{{ data.dateOfStatement }}</div>
                        </div>
                    </div>
                    <div class="col-6" v-show="groupBy == 1">
                        <div class="row mb-2">
                            <div class="col-6 text-end"><label class="fw-bold text-uppercase">Company ID:</label></div>
                            <div class="col-6">{{ data.companyId }}</div>
                        </div>
                    </div>
                </div>
                <hr class="d-none d-print-block" />
            </div>
            <!--GRID BY COMPANY-->
            <by-company-grid :data="data" v-if="groupBy == 1"></by-company-grid>
            <by-type-of-payment-grid :data="data" v-if="groupBy == 2"></by-type-of-payment-grid>
            <by-bank-account-grid :data="data" v-if="groupBy == 3"></by-bank-account-grid>
        </div>
    </layout>
</template>

<script>
import axios from 'axios';
import moment from 'moment';
import Layout from '@/views/layouts/Main';
import Loading from '@/components/Loading';
import { Datepicker } from 'vanillajs-datepicker';
import ByCompanyGrid from '@/views/accounting/grids/PaymentStatementByCompanyGrid';
import ByBankAccountGrid from '@/views/accounting/grids/PaymentStatementByBankAccountGrid';
import ByTypeOfPaymentGrid from '@/views/accounting/grids/PaymentStatementByTypeOfPaymentGrid';

export default {
    name: 'DetailReport',
    components: {
        Layout,
        Loading,
        ByCompanyGrid,
        ByBankAccountGrid,
        ByTypeOfPaymentGrid,
    },
    data() {
        return {
            dateRangeType: 0,
            fromDate: null,
            toDate: null,
            country: 0,
            companyId: 0,
            dateRange: [],
            countries: [],
            companies: [{ id: 0, value: 'ALL COMPANIES' }],
            agents: [],
            loading: false,
            data: null,
            showProfit: true,
            groupBy: 1,
            creditCardPaymentsOnly: false,
            ACHPaymentsOnly: false,
            groupByData: [
                {
                    value: 1,
                    text: 'Company',
                },
                {
                    value: 2,
                    text: 'Type of Payment',
                },
                {
                    value: 3,
                    text: 'Bank Account',
                },
            ],
        };
    },
    mounted() {
        this.fromDate = new Datepicker(document.getElementById('fromDate'));
        this.toDate = new Datepicker(document.getElementById('toDate'));
        this.loadDateRange();
        this.loadCompanies();
        this.dateRangeType = 1;
    },
    methods: {
        loadDateRange() {
            this.dateRange = [
                {
                    id: 1,
                    value: 'Today',
                    range: {
                        from: moment().format('MM/DD/YYYY'),
                        to: moment().format('MM/DD/YYYY'),
                    },
                },
                {
                    id: 2,
                    value: 'Yesterday',
                    range: {
                        from: moment().subtract(1, 'days').format('MM/DD/YYYY'),
                        to: moment().subtract(1, 'days').format('MM/DD/YYYY'),
                    },
                },
                {
                    id: 3,
                    value: 'This Week',
                    range: {
                        from: moment().day('0').format('MM/DD/YYYY'),
                        to: moment().day('6').format('MM/DD/YYYY'),
                    },
                },
                {
                    id: 4,
                    value: 'Last Week',
                    range: {
                        from: moment().day('0').subtract(7, 'days').format('MM/DD/YYYY'),
                        to: moment().day('6').subtract(7, 'days').format('MM/DD/YYYY'),
                    },
                },
                {
                    id: 5,
                    value: 'This Month',
                    range: {
                        from: moment().startOf('month').format('MM/DD/YYYY'),
                        to: moment().format('MM/DD/YYYY'),
                    },
                },
                {
                    id: 6,
                    value: 'Last Month',
                    range: {
                        from: moment().subtract(1, 'months').startOf('month').format('MM/DD/YYYY'),
                        to: moment().subtract(1, 'months').endOf('month').format('MM/DD/YYYY'),
                    },
                },
                {
                    id: 7,
                    value: 'Past 30 Days',
                    range: {
                        from: moment().subtract(30, 'days').format('MM/DD/YYYY'),
                        to: moment().format('MM/DD/YYYY'),
                    },
                },
            ];
        },
        loadCompanies() {
            axios
                .get('/niloagent/accounting/companieschildrenaccounting')
                .then((response) => {
                    this.companies = this.companies.concat(response.data);
                })
                .catch((error) => {
                    this.$toast.error(error.response.data.Message);
                });
        },
        displayData() {
            this.loading = true;
            axios
                .get(`/niloagent/accounting/paymentstatement`, {
                    params: {
                        agentId: this.companyId,
                        groupId: this.groupBy,
                        creditCard: this.creditCardPaymentsOnly ? 1 : 0,
                        achc: this.ACHPaymentsOnly ? 1 : 0,
                        startDate: this.fromDate.getDate('yyyy-mm-dd'),
                        endDate: this.toDate.getDate('yyyy-mm-dd'),
                    },
                })
                .then((response) => {
                    this.checkAgent(this.agent);
                    this.data = response.data;
                })
                .catch((error) => {
                    this.data = null;
                    this.$toast.error(error.response.data.Message);
                })
                .finally(() => {
                    this.loading = false;
                });
        },
        exportData() {},
        print() {
            window.print();
        },
        checkAgent(value) {
            if (value > 0) {
                this.showProfit = false;
            } else {
                this.showProfit = true;
            }
        },
        onChangeGroupBy() {
            this.data = null;
        },
    },
    computed: {
        isAgent() {
            return this.$store.getters['agent/isAgent'];
        },
    },
    watch: {
        dateRangeType(value) {
            if (value > 0) {
                const selected = this.dateRange.find((item) => {
                    return item.id === value;
                });
                this.fromDate.setDate(selected.range.from);
                this.toDate.setDate(selected.range.to);
            }
        },
        creditCardPaymentsOnly(value) {
            if (value) {
                this.ACHPaymentsOnly = false;
            }
        },
        ACHPaymentsOnly(value) {
            if (value) {
                this.creditCardPaymentsOnly = false;
            }
        },
    },
};
</script>

<style lang="scss" scoped>
.logo {
    max-width: 100px;
}
@page {
    size: Letter;
}
.table {
    font-size: 0.71rem !important;
}
</style>
