<template>
    <div class="container">
        <div class="row">
            <div class="col-4 text-start">
                <img src="@/assets/img/inter-co-logo.svg" class="logo" alt="Inter Bank" />
            </div>
            <div class="col-8 text-end d-flex">
                <h1 class="fs-5 mb-0 mt-auto ms-auto text-uppercase fw-bold">Acknowledgment Refund Receipt</h1>
            </div>
        </div>
        <hr />
        <loading :show="loading"></loading>
        <div v-show="data && !loading">
            <div class="d-flex">
                <div class="fs-6">
                    <div class="mb-2"><label class="me-2">Refund Date:</label> {{ data.date }}</div>
                    <div class="mb-2"><label class="me-2">Invoice No:</label> {{ data.invoiceId }}</div>
                    <div class="mb-2"><label class="me-2">Total Amount (including fee):</label> ${{ data.totalAmount }}</div>
                </div>
                <div class="ms-auto d-print-none">
                    <button type="button" @click="print" class="btn btn-outline-primary">Print</button>
                </div>
            </div>

            <div class="fs-6 mt-4">
                <h5 class="text-decoration-underline fw-bold mb-3">Sender Information</h5>
                <div class="mb-2"><label class="me-2">Full Name:</label> {{ senderFullname }}</div>
                <div class="mb-2"><label class="me-2">Phone:</label> {{ data.senderPhone }}</div>
                <div class="mb-2"><label class="me-2">Address:</label> {{ data.senderAddress }}</div>
                <div class="mb-2"><label class="me-2">City/State:</label> {{ data.senderCity }} / {{ data.senderState }}</div>
                <div class="mb-2"><label class="me-2">Zip Code:</label> {{ data.senderZipCode }}</div>
            </div>

            <div class="fs-6 mt-4">
                <h5 class="text-decoration-underline fw-bold mb-3">Agent Information</h5>
                <div class="mb-2"><label class="me-2">Agency Code:</label> {{ data.agentGivenId }}</div>
                <div class="mb-2"><label class="me-2">Agency Name:</label> {{ data.agentCompanyName }}</div>
                <div class="mb-2"><label class="me-2">State License #:</label> {{ data.licenceState }}</div>
                <div class="mb-2"><label class="me-2">Teller Name:</label> {{ ___________________________________________ }}</div>
            </div>

            <p class="fs-6 mt-4">I, {{ senderFullname }}, confirm receiving the total refund amount for the cancelled remittance as listed and dated above. Hereby, <span class="fw-bold">Inter & Co Payments, Inc, DBA INTER and its Agent</span> declare no longer liable for the payment of the referred remittance.</p>

            <div class="row fs-5 mt-4">
                <div class="col-6">
                    <label>Received By:</label>
                    <div>x_________________________________</div>
                    <div class="small">(Sender Signature)</div>
                </div>
                <div class="col-6">
                    <label>Refunded By:</label>
                    <div>x_________________________________</div>
                    <div class="small">(Teller Signature)</div>
                </div>
            </div>

            <p class="mt-5 fs-6">This form must be fully completed and signed by SENDER and AGENT. Please email or fax back to Pronto Money Transfer, Inc DBA INTER at <a href="mailto:helpdesk@pontualmt.com">helpdesk@pontualmt.com</a> or <span class="fw-bold">(310) 388-5457</span>.</p>
        </div>
    </div>
</template>

<script>
import axios from 'axios';
import Loading from '@/components/Loading';

export default {
    name: 'RefundReceipt',
    components: {
        Loading,
    },
    props: {
        orderId: Number,
    },
    data() {
        return {
            data: null,
            loading: false,
            signing: false,
            signed: false,
        };
    },
    mounted() {
        this.load();
    },
    methods: {
        load() {
            this.loading = true;
            axios
                .get(`/niloagent/TaskController/RefundOrder`, {
                    params: {
                        id: this.orderId,
                    },
                })
                .then((response) => {
                    this.data = response.data;
                })
                .catch((error) => {
                    this.data = null;
                    this.$toast.error(error.response.data.Message);
                })
                .finally(() => {
                    this.loading = false;
                });
        },
        print() {
            window.print();
        },
    },
    computed: {
        senderFullname() {
            return this.data ? this.data.senderFirstName + ' ' + this.data.senderLastName : '';
        },
    },
};
</script>

<style lang="scss" scoped>
.fs-5 {
    font-size: 1em;
}
.logo {
    max-width: 150px;
}
</style>
