<template>
    <div>
        <loading :show="loading"></loading>
        <div v-if="data">
            <div class="container border border-dark mt-3">
                <div class="row align-content-center d-flex">
                    <div class="col-6 border-end border-dark p-3">
                        <img src="@/assets/img/inter-co-logo.svg" class="logo" alt="Inter Bank" />
                    </div>
                    <div class="col-6 p-3 text-center">
                        <button type="button" @click="print" class="btn btn-outline-primary text-uppercase me-1 d-print-none">print</button>
                        <button type="button" @click="close" class="btn btn-outline-primary text-uppercase ms-1 d-print-none">close</button>
                    </div>
                </div>
                <div class="row">
                    <div class="col-6 border-end border-dark p-3 border-top">
                        Inter & Co Payments, Inc.<br />
                        dba inter&co<br />
                        501 Brickell Key Drive, Ste 202<br />
                        Miami, FL 33131<br />
                        Tel.: 1-877-766-8825
                    </div>
                    <div class="col-6 p-3 border-top border-dark">
                        <div class="fw-bold mb-2 text-uppercase">NOT A RECEIPT{{ $t('pre-receipt.not-a-receipt') }}</div>
                        <label class="fw-bold">Date{{ $t('pre-receipt.date') }}: </label>
                        <div>{{ data.dateTime }}</div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-6 border-end border-dark p-3 border-top">
                        <label class="fw-bold">Transfer Amount{{ $t('pre-receipt.transfer-amount') }}:</label>
                    </div>
                    <div class="col-6 p-3 border-top border-dark">${{ data.amountSent }}</div>
                </div>
                <div class="row">
                    <div class="col-6 border-end border-dark p-3 border-top">
                        <label class="fw-bold">Transfer Fees{{ $t('pre-receipt.transfer-fee') }}:</label>
                    </div>
                    <div class="col-6 p-3 border-top border-dark">+${{ data.totalFees }}</div>
                </div>
                <div class="row">
                    <div class="col-6 border-end border-dark p-3 border-top">
                        <label class="fw-bold">Transfer Taxes{{ $t('pre-receipt.transfer-taxes') }}:</label>
                    </div>
                    <div class="col-6 p-3 border-top border-dark">+$0.00</div>
                </div>
                <div class="row">
                    <div class="col-6 border-end border-dark p-3 border-top">
                        <label class="fw-bold">Total{{ $t('pre-receipt.total') }}:</label>
                    </div>
                    <div class="col-6 p-3 border-top border-dark">${{ data.totalCollected }}</div>
                </div>
                <div class="row">
                    <div class="col-6 border-end border-dark p-3 border-top">
                        <label class="fw-bold">Exchange Rate{{ $t('pre-receipt.exchange-rate') }}:</label>
                    </div>
                    <div class="col-6 p-3 border-top border-dark">US$1.00 = {{ data.exchangeRate }} {{ data.currencyIsoCode }}</div>
                </div>
                <div class="row">
                    <div class="col-6 border-end border-dark p-3 border-top">
                        <label class="fw-bold">Transfer Amount{{ $t('pre-receipt.amount-to-be-delivered') }}:</label>
                    </div>
                    <div class="col-6 p-3 border-top border-dark">{{ data.amountPaid }} {{ data.currencyIsoCode }}</div>
                </div>
                <div class="row">
                    <div class="col-6 border-end border-dark p-3 border-top">
                        <label class="fw-bold">Other Fees{{ $t('pre-receipt.other-fees') }}:</label>
                    </div>
                    <div class="col-6 p-3 border-top border-dark">-0.00 {{ data.currencyIsoCode }}</div>
                </div>
                <div class="row">
                    <div class="col-6 border-end border-dark p-3 border-top">
                        <label class="fw-bold">Total to Recipient{{ $t('pre-receipt.total-to-recipient') }}:</label>
                    </div>
                    <div class="col-6 p-3 border-top border-dark">{{ data.amountPaid }} {{ data.currencyIsoCode }}</div>
                </div>
            </div>
            <div class="container mt-2 pb-2">
                Recipient may receive less due to fees charged by the recipient's bank and foreign taxes. {{ $t('pre-receipt.footer-text-1') }}.<br />
                *Exchange Rate is subject to change. {{ $t('pre-receipt.footer-text-2') }}.
            </div>
            <div class="container mt-2 pb-2 border-dotted">
                <b>Consumer Fraud Warning:</b> Fraudulent transactions may result in the loss of your money with no recourse. If you think you have been or might be a victim of fraud, please contact us immediately by telephone at 1-877-766-8825. <br /><br />
                <b>{{ $t('pre-receipt.footer-text-3') }}</b
                >{{ $t('pre-receipt.footer-text-4') }}
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios';
import Loading from '@/components/Loading.vue';

export default {
    name: 'PreReceiptPrint',
    components: {
        Loading,
    },
    data() {
        return {
            data: null,
            amount: 0,
            countryId: 0,
            loading: false,
        };
    },
    mounted() {
        this.amount = this.$route.query.paymentAmount;
        this.countryId = this.$route.query.country;
        this.load();
    },
    methods: {
        load() {
            this.loading = true;
            axios
                .get('/niloagent/prereceipt/getrates', {
                    params: {
                        country: this.countryId,
                        amount: this.amount,
                    },
                })
                .then((response) => {
                    this.data = response.data;
                    this.$i18n.locale = this.data.locale;
                })
                .finally(() => {
                    this.loading = false;
                });
        },
        print() {
            window.print();
        },
        close() {
            window.close();
        },
    },
};
</script>

<style lang="scss" scoped>
.logo {
    max-width: 150px;
}
.border-dotted {
    border-bottom: dotted;
}
</style>
