<template>
    <layout>
        <div class="container pt-4 d-print-none mb-5">
            <h1 class="page-title text-primary mb-5 text-uppercase">Current Balance</h1>
            <div class="d-flex">
                <div>
                    <button type="button" @click="displayData" class="btn btn-primary text-uppercase" :disabled="loading"><i class="fas fa-search"></i> {{ loading ? 'Wait...' : 'Display' }}</button>
                    <button type="button" @click="exportData" class="btn btn-primary text-uppercase ms-3" :disabled="loading" v-show="false"><i class="fas fa-download"></i> <span v-show="!loading">Export</span><span v-show="loading">Waiting...</span></button>
                </div>
                <button type="button" class="btn btn-outline-primary text-uppercase ms-auto" @click="print" :disabled="!data"><i class="fas fa-print"></i> Print</button>
            </div>
        </div>
        <loading :show="loading" />
        <div v-if="data && !loading">
            <div class="container mb-4">
                <div class="row d-none d-print-flex">
                    <div class="col-6 text-start">
                        <img src="@/assets/img/inter-co-logo.svg" class="logo" alt="Inter Bank" />
                    </div>
                    <div class="col-6 text-end d-flex">
                        <h1 class="fs-4 mb-0 mt-auto ms-auto">Payments Statement</h1>
                    </div>
                </div>
                <hr class="d-none d-print-block" />
                <div class="row">
                    <div class="col-6">
                        <div class="row mb-2">
                            <div class="col-6 text-end"><label class="fw-bold text-uppercase">Date of Statement:</label></div>
                            <div class="col-6">{{ data.dateOfStatement }}</div>
                        </div>
                    </div>
                </div>
                <hr class="d-none d-print-block" />
            </div>
            <div class="container-fluid" v-if="data.items.length > 0">
                <div class="table-responsive d-print-table">
                    <table class="table table-borderless">
                        <thead class="bg-light">
                            <tr>
                                <th class="">ID</th>
                                <th>COMPANY NAME</th>
                                <th>BALANCE</th>
                                <th>CREDIT LIMIT</th>
                                <th class="text-end">MARGIN</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(item, index) in data.items" :key="index">
                                <td>
                                    {{ item.giveId }}
                                </td>
                                <td>
                                    {{ item.companyName }}
                                </td>
                                <td>
                                    {{ item.balanceFormated }}
                                </td>
                                <td>
                                    {{ item.creditLimit }}
                                </td>
                                <td class="text-end">
                                    {{ item.marginFormated }}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div class="p-2 d-flex">
                    <div>Total:</div>
                    <div class="ms-auto">{{ data.total }}</div>
                </div>
            </div>
            <div class="container" v-else>
                <div class="alert alert-warning text-center container">
                    <h4 class="fw-semibold">There are no transactions in this report</h4>
                </div>
            </div>
        </div>
    </layout>
</template>

<script>
import axios from 'axios';
import Layout from '@/views/layouts/Main';
import Loading from '@/components/Loading';

export default {
    name: 'CurrentBalance',
    components: {
        Layout,
        Loading,
    },
    data() {
        return {
            company: 0,
            agent: 0,
            agents: [],
            loading: false,
            data: null,
        };
    },
    methods: {
        displayData() {
            this.loading = true;
            axios
                .get(`/niloagent/accounting/currentbalance`)
                .then((response) => {
                    this.data = response.data;
                })
                .catch((error) => {
                    this.data = null;
                    this.$toast.error(error.response.data.Message);
                })
                .finally(() => {
                    this.loading = false;
                });
        },
        exportData() {},
        print() {
            window.print();
        },
    },
};
</script>

<style lang="scss" scoped>
.logo {
    max-width: 100px;
}
@page {
    size: Letter;
}
.table {
    font-size: 0.71rem !important;
}
</style>
