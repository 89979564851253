<template>
    <div class="">
        <div class="container border border-dark mt-2">
            <div class="row">
                <div class="col-3 border-end border-dark text-center d-flex p-2">
                    <img alt="Inter Bank" src="@/assets/img/inter-co-logo.svg" class="logo-inter w-100 my-auto mx-auto" />
                </div>
                <div class="col-3 border-end border-dark d-flex">
                    <div class="m-auto text-center" v-html="data.license"></div>
                </div>
                <div class="col-3 border-end border-dark">
                    {{ data.agentCompanyName }}<br />
                    {{ data.agentCompanyGivemId }}<br />
                    {{ data.agentCompanyAddress }} {{ data.agentCompanyCity }}, {{ data.agentCompanyState }} - {{ data.agentCompanyCountryName }}<br />
                    {{ data.agentCompanyTelephone }}
                </div>
                <div class="col-3">
                    <div class="row bg-black text-white p-3 text-uppercase text-center border-bottom border-dark">
                        <div class="col-12">customer copy/Copia cliente</div>
                    </div>
                    <div class="row p-2">
                        Date : {{ data.orderDateTime }}<br />
                        Invoice No. {{ data.invoiceNr }}:
                    </div>
                    <div class="row p-2 border-top border-dark">Date: {{ today }}</div>
                </div>
            </div>
            <div class="row border-top border-dark">
                <div class="col-6">
                    <div class="row">
                        <div class="col-12 text-center p-2 fw-bold border-end border-dark">Sender / Remetente</div>
                    </div>
                    <div class="row border-top border-dark border-end">
                        <div class="col-4 p-2 border-end border-dark">
                            Name<br />
                            Address<br />
                            Tel.
                        </div>
                        <div class="col-8 p-2">
                            {{ data.senderFirstName }} {{ data.senderLastName }}<br />
                            {{ data.senderCity }}, {{ data.senderState }} {{ data.senderZipCode }}<br />
                            {{ data.senderTelephone1 }}
                        </div>
                    </div>
                </div>
                <div class="col-6 d-flex">
                    <div class="m-auto">
                        <button class="btn btn-outline-primary me-1 d-print-none">PRINT</button>
                        <button class="btn btn-outline-primary ms-1 d-print-none">CLOSE</button>
                    </div>
                </div>
            </div>
            <div class="row border-top border-dark">
                <div class="col-6 text-center border-end border-dark p-2 fw-bold">Transaction Info / Quantidade a Enviar</div>
                <div class="col-6 text-center p-2 fw-bold">Location of Payment / Local de Pagamento</div>
            </div>
            <div class="row border-top border-dark">
                <div class="col-3 border-end border-dark p-2">
                    Amount<br />
                    Amount Received
                </div>
                <div class="col-3 p-2 border-end border-dark">
                    {{ data.amountToBeSentUsd }}<br />
                    {{ data.amountToBeDelivered }}
                </div>
                <div class="col-3 p-2 border-end border-dark">
                    Carrier<br />
                    Phone No.
                </div>
                <div class="col-3 p-2">
                    <br />
                    {{ data.beneficiaryTelephone1 }}
                </div>
            </div>
        </div>
        <div class="container mt-4 px-0">
            <p><span class="fw-bold">IMPORTANT NOTICE:</span> This transaction is subject to the terms and conditions on this receipt. By conducting this transaction you affirm that you accept these terms and conditions.</p>

            <p><span class="fw-bold">Terms of Service:</span> This is a nonrefundable transaction. It is your responsibility to ensure that the transaction details you provide are accurate before submission. After you submit the transaction the value or credit is added to the recipient's account - and cannot be changed, cancelled or refunded. The transaction is limited to the addition of credit or value to the recipient's account in a foreign country as established by the applicable carrier or service provider. The credit or value cannot be transferred and may expire according to the terms and conditions established by the carrier or service provider. PMT, its employees and representatives are not responsible for loss, theft or unlawful or unauthorized use of these services. These terms and conditions are subject to change without notice.</p>

            <p><span class="fw-bold">The Bill Payment Transaction (Domestic):</span> It may take up to three business days to process this transaction on the biller company.</p>

            <p><span class="fw-bold">Transactions Involving Foreign Currency:</span> When you pay for a transaction in $USD the value will be received denominated in the recipient's foreign currency. A currency exchange rate will apply. The exchange rate will be determined based on the rate in place at the time credit or value is added to the recipient's account. PMT and its representatives may receive a modest profit on the exchange rate. This additional revenue, if any, is in an additional cost to the consumer and/or recipient.</p>

            <p><span class="fw-bold">NOTA IMPORTANTE:</span> Essa transação está sujeita aos termos e condições deste recibo. Ao proceder com essa transação você concorda com estes termos e condições.</p>

            <p><span class="fw-bold">Termos de Serviço:</span> Esta transação não é reembolsavel. É sua responsabilidade de prover os dados corretos antes de submeter a transação. Após submeter a transação, o valor ou crédito é adicionado a conta do beneficiário e não poderá ser trocado, cancelado ou reembolsado. A transação é limitada a adição de crédito ou um valor, na conta do beneficiário no pais de destino, de acordo com o provedor do serviço. PMT, seus funcionários e representantes não sao responsaveis pela perda, roubo ou uso illegal ou não autorizado destes serviços. Estes termos e condições podem mudar sem prévio aviso.</p>

            <p><span class="fw-bold">Transação Bill Payment (Doméstica):</span> Essa transação poderá levar até tres dias úteis para ser processada pela companhia provedora do serviço.</p>

            <p><span class="fw-bold">Transações Envolvendo Moeda Estrangeira:</span> quando você paga por uma trasnsação em $USD, o valor será recebido na moeda do pais de destino. Um valor de taxa de cambio sera aplicada. A taxa de cambio se baseia na taxa em vigor no momento em que o crédito ou valor é aplicado na conta do beneficiário. PMT e seus representantes talvez recebam uma quantia modesta sobre esta taxa. Este ganho, se existir, é um custo adicional ao cliente ou beneficiário.</p>
        </div>
    </div>
</template>

<script>
import axios from 'axios';
import moment from 'moment';

export default {
    name: 'TopUpReceipt',
    props: {
        orderId: Number,
    },
    data() {
        return {
            data: null,
        };
    },
    mounted() {
        this.load();
    },
    methods: {
        load() {
            this.loading = true;
            axios
                .get(`/niloagent/order/${this.orderId}/detail/receipt`)
                .then((response) => {
                    this.data = response.data;
                    this.$i18n.locale = this.data.locale;
                })
                .catch((error) => {
                    this.data = null;
                    this.$toast.error(error.response.data.Message);
                })
                .finally(() => {
                    this.loading = false;
                });
        },
    },
    computed: {
        today() {
            return moment().format('MM/DD/YYYY');
        },
    },
};
</script>

<style lang="scss" scoped>
.container {
    font-size: 12px !important;
}
.logo-inter {
    max-width: 120px;
}
</style>
