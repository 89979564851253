<template>
    <Layout class="bg-light" v-if="isAuthenticated">
        <div class="container pt-5">
            <h1 class="page-title text-primary mb-3 text-uppercase" id="pageTitle">Home</h1>
            <div class="row">
                <div class="col-md-12 col-lg pt-4">
                    <Menu></Menu>
                    <div class="d-flex mt-4">
                        <ul class="list-unstyled list-inline mx-auto text-uppercase">
                            <li class="list-inline-item" v-if="isAgent">
                                <a href="#" @click="openModalAnnouncements">Announcements & Messages</a>
                            </li>
                            <li class="list-inline-item" v-if="isAgent"><span class="text-primary">|</span></li>
                            <li class="list-inline-item">
                                <router-link to="/tasks" name="btnTasks" class="">
                                    <span class="">Tasks</span>
                                </router-link>
                            </li>
                        </ul>
                    </div>
                </div>
                <div class="col-md-1 col-lg-1 d-none d-lg-block" v-if="isAgent">
                    <div class="h-100 divider-h mx-auto"></div>
                </div>
                <div class="col-md-12 col-lg-3 pt-4" v-if="isAgent">
                    <h3 class="text-center fs-5 mb-0">Exchange Rate</h3>
                    <div class="fs-6 text-uppercase text-gray text-center mb-2 small">Calculator</div>
                    <calculator></calculator>
                </div>
            </div>
            <modal-announcements ref="announcements"></modal-announcements>
        </div>
    </Layout>
</template>

<script>
import axios from 'axios';
import Menu from '@/components/Menu';
import Layout from '@/views/layouts/Main';
import Calculator from '@/components/Calculator';
import ModalAnnouncements from '@/components/ModalAnnouncements';

export default {
    name: 'Home',
    components: {
        Menu,
        Layout,
        Calculator,
        ModalAnnouncements,
    },
    data() {
        return {
            exchangeRate: 0,
            showExchangeRate: false,
            loadingExchangeRate: false,
        };
    },
    mounted() {
        this.getRefundForms();
        this.getIncompleteOrdersCount();
        //this.checkRemessaPremiada(); //Código comentado devido ao fim da promoçao. Nao foi removido pra facilidar a ativaçao da mesma quando necessário.
    },
    methods: {
        openModalAnnouncements() {
            this.$refs.announcements.openModal();
        },
        getExchangeRate() {
            this.loadingExchangeRate = true;
            axios
                .get('/niloagent/createorder/filter/ratesandcosts?SenderCountryId=1&RecipientCountryId=42&NetAmountSent=1&CurrencySentId=1&CurrencyOfPayment=2&TypeOfPaymentID=31')
                .then((response) => {
                    this.exchangeRate = response.data.formattedResponse;
                    this.showExchangeRate = true;
                })
                .catch((error) => {
                    this.$toast.error(error.response.data.Message);
                    this.showExchangeRate = false;
                })
                .finally(() => {
                    this.loadingExchangeRate = false;
                });
        },
        getIncompleteOrdersCount() {
            this.$store.dispatch('getIncompleteOrdersCount');
        },
        getRefundForms() {
            axios
                .get('/niloagent/home/refundForms')
                .then((response) => {
                    this.data = response.data;
                    if (this.data.toString() !== '0') this.$toast.info('You have ' + this.data.toString() + ' Refund Forms pending to complete!');
                })
                .catch((error) => {
                    this.$toast.error(error.response.data.Message);
                });
        },
        checkRemessaPremiada() {
            if (localStorage.getItem('dateRemessaPremiada')) {
                const time = new Date(localStorage.getItem('dateRemessaPremiada'));
                const now = new Date();
                const diff = now.getTime() - time.getTime();
                if (diff >= +process.env.VUE_APP_SHOW_REMESSA_PREMIADA) {
                    this.$router.push({ name: 'RemessaPremiada' });
                }
            } else {
                this.$router.push({ name: 'RemessaPremiada' });
                localStorage.setItem('dateRemessaPremiada', new Date());
            }
        },
    },
    computed: {
        isAuthenticated() {
            return this.$store.getters['auth/isAuthenticated'];
        },
        showAnnouncements() {
            return !this.$store.state.agent.permissions.includes('TRAINING');
        },
        isAgent() {
            return this.$store.getters['agent/isAgent'];
        },
    },
};
</script>

<style lang="scss" scoped>
h2 {
    font-size: 1.1rem;
}
.divider-h {
    width: 1px;
    background-color: #bdbdbd;
}
layout {
    height: 100vh;
}
a {
    text-underline-offset: 5px;
}
</style>
