<template>
    <layout>
        <div v-if="emptyCompany" class="container">
            <div class="alert mt-5 text-center alert-warning">
                <h2>
                    <span class="fw-bold">{{ 'Company bank account inactive or not registered.' }}</span>
                </h2>
                <br />
                <span class="fw-bold">{{ 'The company bank account was not found. It may be inactive or not registered. Please contact the support team to proceed with sending the receipt.' }}</span> <slot></slot>
            </div>
        </div>

        <div v-else class="container pb-4">
            <h1 class="page-title text-primary mt-3 text-uppercase">Agent Receipt Upload</h1>
            <div class="row small-row justify-content-center mt-3 form-container">
                <!-- FORM SECTION -->
                <div class="col-lg-4">
                    <div class="card form-section" :class="{ 'border-danger': this.verifyFormBorder }">
                        <!-- COMPANY NAME -->
                        <div class="card-header">
                            <div class="col-lg-12 mb-2" v-if="formData">
                                <div class="mt-2" v-if="formData && formData.companies && formData.companies.length > 1">
                                    <label for="company" class="form-label section-label">Company </label>
                                    <v-select :options="formData.companies" :clearable="false" :reduce="(item) => item.id" label="value" v-model="companyId"></v-select>
                                </div>
                                <div class="mt-2" v-if="formData && formData.companies && formData.companies.length == 1">
                                    <span for="company" class="form-label section-label">{{ formData.companies[0].value }}</span>
                                </div>
                            </div>
                            <div class="col-md-12 mb-2" v-if="!formData">
                                <label class="form-label">Loading.... </label>
                            </div>
                        </div>

                        <div class="card-body">
                            <div class="col-lg-12">
                                <!-- AMOUNT ON DEPOSIT RECEIPT -->
                                <div class="row mt-3">
                                    <div class="col-md-12 mb-3">
                                        <label for="paymentAmount" class="form-label">Amount on deposit receipt</label><span class="mandatory-mark tiny-mandatory-mark"> *</span>
                                        <formatted-input :sm="false" @blur="verifyPaymentAmountError" v-model="paymentAmount" :error="this.errors.paymentAmount" :inputValue="parseFloat(this.paymentAmount).toFixed(2)" _class="form-control"></formatted-input>
                                    </div>
                                </div>

                                <!-- DATE ON DEPOSIT RECEPIT -->
                                <div class="row mt-3">
                                    <div class="col-md-12">
                                        <label for="paymentDate" class="form-label">Date on deposit receipt</label><span class="mandatory-mark tiny-mandatory-mark"> *</span>
                                        <div class="input-group mb-3">
                                            <span class="input-group-text text-black-50 calendar-icon"><i class="fas fa-calendar-day"></i></span>
                                            <input type="text" @blur="verifyPaymentDateError" class="form-control datePicker datePicker-input" id="paymentDate" :class="{ 'border-danger': this.errors.paymentDate }" autocomplete="off" />
                                        </div>
                                    </div>
                                </div>

                                <!-- FORM OF PAYMENT -->
                                <div class="row mt-3">
                                    <div class="col-md-12 mb-3" v-if="formData">
                                        <label for="formOfPayment" class="form-label">Select form of payment</label><span class="mandatory-mark tiny-mandatory-mark"> *</span>
                                        <select class="form-select" v-model="formOfPaymentId" id="formOfPayment" @blur="verifyFormOfPaymentError" :class="{ 'border-danger': this.errors.formOfPayment }">
                                            <option value="0" selected>- Select -</option>
                                            <option v-for="item in formData.formOfPayments" :value="item.id" :key="item.id">{{ item.value }}</option>
                                        </select>
                                    </div>
                                    <div class="col-md-12 mb-3" v-if="!formData">
                                        <label class="form-label">Loading.... </label>
                                    </div>
                                </div>

                                <!-- COMPANY BANK ACCOUNT -->
                                <div class="row mt-3">
                                    <div class="col-md-12 mb-3" v-if="formData">
                                        <label for="companyAccount" class="form-label">Company bank account</label><span class="mandatory-mark tiny-mandatory-mark"> *</span>
                                        <div v-if="formData && formData.companyAccounts && formData.companyAccounts.length > 1">
                                            <select class="form-select" v-model="companyBankAccountId" id="companyAccount" @blur="verifyCompanyAccountError" :class="{ 'border-danger': this.errors.companyAccount }">
                                                <option value="0" selected>- Select -</option>
                                                <option v-for="item in formData.companyAccounts" :value="item.id" :key="item.id">{{ item.value }}</option>
                                            </select>
                                        </div>
                                        <div v-if="formData && formData.companyAccounts && formData.companyAccounts.length == 1">
                                            <select class="form-select" v-model="companyBankAccountId" id="companyAccount" @blur="verifyCompanyAccountError" :class="{ 'border-danger': this.errors.companyAccount }">
                                                <option value="0" selected>- Select -</option>
                                                <option :value="formData.companyAccounts[0].id" selected>{{ formData.companyAccounts[0].value }}</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div class="col-md-12 mb-3" v-if="!formData">
                                        <label class="form-label">Loading.... </label>
                                    </div>
                                    <div class="col-md-12 mb-3" v-else><span class="mandatory-mark tiny-mandatory-mark">* </span><label class="form-label">Fill out a form to enable the receipt to be sent</label></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <!-- RECEIPT IMAGE UPLOAD SECTION -->
                <div class="col-lg-4">
                    <div class="card form-section">
                        <div class="card-header">
                            <div class="row">
                                <div class="col-md-12 mb-2">
                                    <div class="mt-2"><span class="form-label section-label">Receipt image</span><span class="mandatory-mark tiny-mandatory-mark"> *</span></div>
                                </div>
                            </div>
                        </div>
                        <div class="card-body">
                            <div class="row">
                                <div class="col-md-12">
                                    <label class="text-uppercase" v-if="isLoadingImage"> Loading image.... </label>
                                    <img class="no-image-icon" src="@/assets/img/no-image-icon.svg" alt="No image was uploaded" v-if="!uploadedReceiptImage" />
                                    <img class="receipt-image" :src="uploadedReceiptImage" alt="Captured Image" @error="handleImageLoadError" v-if="uploadedReceiptImage" />
                                </div>
                            </div>
                            <div class="row position-absolute fixed-bottom mb-3 mx-1 d-flex justify-content-center">
                                <div class="row">
                                    <div class="col-lg-4 px-1">
                                        <button class="btn btn-sm btn-primary text-uppercase upload-receipt-button" @click="uploadReceiptImage">
                                            <i class="fas fa-upload button-icon"></i>
                                            <span>Browse</span>
                                        </button>
                                    </div>
                                    <div class="col-lg-4 px-1">
                                        <button class="btn btn-sm btn-primary text-uppercase upload-receipt-button picture-button" @click="openWebcamModal">
                                            <i class="fas fa-camera button-icon"></i>
                                            <span>Webcam</span>
                                        </button>
                                    </div>
                                    <div class="col-lg-4 px-1">
                                        <button class="btn btn-sm btn-primary text-uppercase upload-receipt-button picture-button" @click="openQrCodeModal">
                                            <i class="fas fa-qrcode button-icon"></i>
                                            <span>QR Code</span>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <!-- BALANCE SECTION -->
                <div class="col-lg-4">
                    <div class="card bg-light border-0" v-if="isAgent && balance">
                        <div class="card-body text-center">
                            <div class="mb-3">
                                <h4 class="mb-0 text-danger">{{ balance.coBalanceFormated }}</h4>
                                <label class="text-uppercase">Your balance</label>
                            </div>
                            <hr />
                            <div class="mb-3">
                                <h4 class="mb-0">${{ balance.receivedPaymentsPendingPostingFormated }}</h4>
                                <label class="text-uppercase">Received payments Pending Approval</label>
                            </div>
                            <hr />
                            <div class="mb-3">
                                <h4 class="mb-0">${{ balance.coCreditLimitFormated }}</h4>
                                <label class="text-uppercase">Operational Limit</label>
                            </div>
                            <hr />
                            <div class="">
                                <h4 class="mb-0">${{ balance.coCreditAvailableFormated }}</h4>
                                <label class="text-uppercase">Available Operational Limit</label>
                            </div>
                        </div>
                    </div>
                    <div class="card b-4">
                        <div class="card-body">
                            <div class="row">
                                <div class="col-md-12 mb-2">
                                    <label class="form-label">Upload Deposit Receipt Requirement:</label>
                                    <ul>
                                        <li>
                                            <label class="form-label">Readable/Not blurry</label>
                                        </li>
                                        <li>
                                            <label class="form-label">All four corners visible and nothing in the way</label>
                                        </li>
                                        <li>
                                            <label class="form-label">Make sure the date, deposit amount and account number are visible</label>
                                        </li>
                                    </ul>
                                    <label class="form-label">This will help ensure a quick review</label>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <button type="button" @click="submitForm" class="btn btn-sm btn-primary mt-4 text-uppercase" :disabled="!formIsFilled || isSubmittingForm">{{ isSubmittingForm ? 'Wait...' : 'Send Receipt' }}</button>
            </div>
        </div>
        <webcam-modal ref="webcamModal" @onReceiptUploadConfirmation="loadWebcamReceiptImage"></webcam-modal>
        <qr-code-modal ref="qrCodeModal" :uploaded-receipt-image-id="uploadedReceiptImageId" @onReceiptUploadConfirmation="loadReceiptImageUploadedFromQrCode"></qr-code-modal>
    </layout>
</template>

<script>
import axios from 'axios';
import Layout from '@/views/layouts/Main';
import FormattedInput from '@/components/CurrencyInput';
import { Datepicker } from 'vanillajs-datepicker';
import WebcamModal from '@/views/accounting/payment-receipt-upload/PaymentReceiptUploadWebcamModal';
import QrCodeModal from '@/views/accounting/payment-receipt-upload/PaymentReceiptUploadQrCodeModal';

export default {
    name: 'AgentReceiptUpload',
    components: {
        Layout,
        WebcamModal,
        QrCodeModal,
        FormattedInput,
    },
    data() {
        return {
            companyId: null,
            paymentAmount: 0,
            paymentDate: null,
            formOfPaymentId: 0,
            companyBankAccountId: 0,
            formData: null,
            uploadedReceiptImageId: null,
            receiptImageUploadURL: null,
            uploadedReceiptImage: null,
            receiptImageFile: null,
            isReceiptImageAlreadyUploaded: false,
            isLoadingImage: false,
            isSubmittingForm: false,
            emptyCompany: false,
            errors: {
                paymentAmount: false,
                paymentDate: false,
                formOfPayment: false,
                companyAccount: false,
            },
        };
    },
    mounted() {
        this.uploadedReceiptImageId = new Date().valueOf();
        this.receiptImageUploadURL = `${window.location.origin}/accounting/upload/${this.uploadedReceiptImageId}`;
        this.paymentDate = new Datepicker(document.getElementById('paymentDate'));
        this.load();
    },
    methods: {
        load() {
            axios
                .get('/niloagent/accounting/paymentsoptions')
                .then((response) => {
                    this.formData = response.data;
                    this.emptyCompany = this.formData.companyAccounts.length == 0;
                })
                .catch((error) => {
                    this.$toast.error(error.response.data.Message);
                });
        },
        loadWebcamReceiptImage(webcamReceiptImage) {
            // convert Base64 to File
            var arr = webcamReceiptImage.split(','),
                mime = arr[0].match(/:(.*?);/)[1],
                bstr = atob(arr[arr.length - 1]),
                n = bstr.length,
                u8arr = new Uint8Array(n);
            while (n--) {
                u8arr[n] = bstr.charCodeAt(n);
            }

            this.uploadedReceiptImage = webcamReceiptImage;
            this.receiptImageFile = new File([u8arr], this.uploadedReceiptImageId, { type: mime });
            this.isReceiptImageAlreadyUploaded = false;
        },
        loadReceiptImageUploadedFromQrCode() {
            this.isLoadingImage = true;
            axios
                .get(`/niloagent/accounting/payment/loadreceiptuploaded?fileName=${this.uploadedReceiptImageId}`)
                .then((response) => {
                    this.uploadedReceiptImage = response.data;
                    this.isReceiptImageAlreadyUploaded = true;
                })
                .catch((error) => {
                    this.$toast.error(error.response.data.Message);
                })
                .finally(() => {
                    this.isLoadingImage = false;
                });
        },
        uploadReceiptImage() {
            const input = document.createElement('input');
            document.body.appendChild(input);
            input.type = 'file';
            input.accept = 'image/*';
            input.capture = 'camera';
            input.addEventListener('change', (event) => {
                this.receiptImageFile = event.target.files[0];
                this.uploadedReceiptImage = URL.createObjectURL(this.receiptImageFile);
                this.isReceiptImageAlreadyUploaded = false;
            });
            input.click();
            document.body.removeChild(input);
            this.verifyForm();
        },
        handleImageLoadError() {
            this.uploadedReceiptImage = null;
            this.$toast.warning('Image was not uploaded. Please, scan the QR Code again to make the upload.');
        },
        submitForm() {
            var submittingFormConfirmationMessage = this.getSubmittingFormConfirmationMessage();
            if (confirm(submittingFormConfirmationMessage) && this.formIsFilled) {
                this.isSubmittingForm = true;
                if (this.isReceiptImageAlreadyUploaded) {
                    this.submitFormData();
                } else {
                    this.submitReceiptImageAndFormData();
                }
            }
        },
        submitReceiptImageAndFormData() {
            const formData = new FormData();
            formData.append('file', this.receiptImageFile);

            axios
                .post('/auth/payment/uploadreceipt', formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    },
                    params: {
                        fileName: this.uploadedReceiptImageId,
                    },
                })
                .then(() => {
                    this.submitFormData();
                })
                .catch(() => {
                    this.$toast.error('Error uploading receipt.');
                });
        },
        submitFormData() {
            axios
                .post('/niloagent/accounting/payment/uploadreceipt', {
                    companyId: this.companyId,
                    paymentAmount: this.paymentAmount,
                    paymentDate: this.paymentDate.getDate('mm/dd/yyyy'),
                    formOfPaymentId: this.formOfPaymentId,
                    companyAccountId: this.companyBankAccountId,
                    fileName: this.uploadedReceiptImageId,
                })
                .then(() => {
                    this.$toast.success('Payment receipt uploaded successfully', { timeout: false });
                    this.resetForm();
                })
                .catch((error) => {
                    this.$toast.error(error.response.data.Message);
                })
                .finally(() => {
                    this.isSubmittingForm = false;
                });
        },
        getSubmittingFormConfirmationMessage() {
            const selectedOption = this.formData.formOfPayments.find((option) => option.id === this.formOfPaymentId);
            var formPaymentText = selectedOption ? selectedOption.value : '';

            const selectedOptionBank = this.formData.companyAccounts.find((option) => option.id === this.companyBankAccountId);
            var BankText = selectedOptionBank ? selectedOptionBank.value : '';

            var title = 'Are you sure you want to upload this payment receipt? \n';
            var amount = ' Amount: $ ' + parseFloat(this.paymentAmount).toFixed(2) + '\n';
            var date = ' Date on deposit Receipt: ' + this.paymentDate.getDate('mm/dd/yyyy') + '\n';
            var formOfPayment = ' Form of Payment: ' + formPaymentText + '\n';
            var companyAccount = ' Company Account: ' + BankText;

            return title + amount + date + formOfPayment + companyAccount;
        },
        async resetForm() {
            this.companyId = null;
            this.paymentAmount = 0;
            this.formOfPaymentId = 0;
            this.companyBankAccountId = 0;
            this.paymentDate.setDate([], { clear: true });
            this.uploadedReceiptImage = null;
            this.uploadedReceiptImageId = new Date().valueOf();
            this.receiptImageUploadURL = `${window.location.origin}/accounting/upload/${this.uploadedReceiptImageId}`;
            this.receiptImageFile = null;
            this.isReceiptImageAlreadyUploaded = false;
            await this.$store.dispatch('getAccountingBalance');
            this.errors.formOfPayment = false;
            this.errors.companyAccount = false;
        },
        openWebcamModal() {
            this.verifyForm();
            this.$refs.webcamModal.show();
        },
        openQrCodeModal() {
            this.verifyForm();
            this.$refs.qrCodeModal.show();
        },
        verifyPaymentAmountError() {
            this.errors.paymentAmount = this.paymentAmount === 0;
        },
        verifyPaymentDateError() {
            typeof this.paymentDate.getDate('mm/dd/yyyy') == 'undefined' ? (this.errors.paymentDate = true) : (this.errors.paymentDate = false);
        },
        verifyFormOfPaymentError() {
            this.errors.formOfPayment = this.formOfPaymentId == 0;
        },
        verifyCompanyAccountError() {
            this.errors.companyAccount = this.companyBankAccountId == 0;
        },
        verifyForm() {
            typeof this.paymentDate.getDate('mm/dd/yyyy') == 'undefined' ? (this.errors.paymentDate = true) : (this.errors.paymentDate = false);
            this.errors.paymentAmount = this.paymentAmount === 0;
            this.errors.formOfPayment = this.formOfPaymentId == 0;
            this.errors.companyAccount = this.companyBankAccountId == 0;
        },
    },
    computed: {
        isAgent() {
            return !this.$store.state.agent.headquarter;
        },
        balance() {
            return this.$store.state.balance.data;
        },
        formIsFilled() {
            var paymentAmountFilled = this.paymentAmount && this.paymentAmount > 0;
            var paymentDateFilled = this.paymentDate && this.paymentDate.dates.length > 0;
            var formOfPaymentFilled = this.formOfPaymentId > 0;
            var companyBankAccountFilled = this.companyBankAccountId > 0;
            return paymentAmountFilled && paymentDateFilled && formOfPaymentFilled && companyBankAccountFilled && this.uploadedReceiptImage;
        },
        verifyFormBorder() {
            return this.errors.companyAccount || this.errors.paymentAmount || this.errors.formOfPayment || this.errors.paymentDate;
        },
    },
    watch: {
        formOfPaymentId() {
            this.verifyFormOfPaymentError();
        },
        companyBankAccountId() {
            this.verifyCompanyAccountError();
        },
    },
};
</script>

<style scoped>
.form-container {
    display: flex;
    height: 480px;
}

.form-section {
    height: 100%;
    position: relative;
}

.receipt-image {
    max-width: 90%;
    max-height: 350px;
    height: auto;
    display: block;
    margin-left: auto;
    margin-right: auto;
    border: 1px solid rgba(0, 0, 0, 0.5);
}

.no-image-icon {
    width: 90%;
    height: 280px;
    display: block;
    margin-left: auto;
    margin-right: auto;
    filter: invert(74%) sepia(9%) saturate(0%) hue-rotate(185deg) brightness(98%) contrast(97%);
}

.upload-receipt-button {
    width: 100%;
    margin-top: 8px;
    bottom: 0;
}

@media only screen and (max-device-width: 768px) {
    .picture-button {
        display: none;
    }
}

.button-icon {
    font-size: 1.1em;
    margin-right: 12px;
}

.section-label {
    font-size: 1.1em;
}
</style>
