<template>
    <html xmlns="http://www.w3.org/1999/xhtml">
        <head>
            <meta http-equiv="Content-Type" content="text/html; charset=utf-8" />
            <title>Customer Receipt</title>
        </head>

        <body bgcolor="#FFFFFF" onafterprint="setTimeout('donotifyMsg()', 1000);" style="border-bottom-color: #000000; border-bottom-style: solid; border-bottom-width: thin; font-family: Arial, Helvetica, sans-serif; font-size: 14px">
            <table width="1000" border="0" align="center" cellpadding="0" cellspacing="0">
                <tr>
                    <td><img srcset="@/assets/img/inter-co-logo.svg" width="200" height="88" alt="Inter Bank" /></td>
                    <td>&nbsp;</td>
                    <td>&nbsp;</td>
                </tr>
            </table>
            <br />
            <br />
            <table width="1000" border="0" align="center" cellpadding="0" cellspacing="0">
                <tr>
                    <td colspan="5" align="center"><strong>COMPLIANCE FORM</strong></td>
                </tr>
                <tr>
                    <td width="158">&nbsp;</td>
                    <td width="390">&nbsp;</td>
                    <td width="198">&nbsp;</td>
                    <td width="103">&nbsp;</td>
                    <td width="151">&nbsp;</td>
                </tr>
                <tr>
                    <td><strong>Agent:</strong></td>
                    <td colspan="2">{{ data.agent }}</td>
                    <td><strong>Date:</strong></td>
                    <td align="right">{{ data.date }}</td>
                </tr>
            </table>
            <br />
            <br />
            <br />

            <table width="1000" border="0" align="center" cellpadding="0" cellspacing="0" class="tbTablesB">
                <tr>
                    <td colspan="7" align="center"><strong>SENDER INFORMATION</strong></td>
                </tr>
                <tr>
                    <td width="143" style="text-align: center">
                        <p><strong>Name:</strong></p>
                    </td>
                    <td width="282">{{ data.senderName }}</td>
                    <td width="111"><strong>Client No.</strong></td>
                    <td>{{ data.senderClienteNumber }}</td>
                    <td width="107" align="center">
                        <p><strong>DOB:</strong></p>
                    </td>
                    <td colspan="2">{{ data.senderBirthDay }}</td>
                </tr>
                <tr>
                    <td style="text-align: center">
                        <p><strong>Address:</strong></p>
                    </td>
                    <td colspan="3">{{ data.senderAddress }}</td>
                    <td align="center">
                        <p><strong>Phone:</strong></p>
                    </td>
                    <td colspan="2">{{ data.senderTelephone }}</td>
                </tr>
                <tr>
                    <td style="text-align: center">
                        <p><strong>City:</strong></p>
                    </td>
                    <td colspan="2">{{ data.senderCity }}</td>
                    <td width="161">
                        <p><strong>State:</strong></p>
                    </td>
                    <td>{{ data.senderState }}</td>
                    <td width="52"><strong>ZIP:</strong></td>
                    <td width="140">{{ data.senderZipCode }}</td>
                </tr>
            </table>
            <br />
            <br />
            <br />
            <table width="1000" border="0" align="center" cellpadding="0" cellspacing="0" class="tbTablesB">
                <tr>
                    <td colspan="4" style="text-align: center"><strong>SENDER DOCUMENTATION</strong></td>
                </tr>
                <tr>
                    <td width="25%">
                        <p><strong>Primary ID Type</strong></p>
                    </td>
                    <td width="25%">
                        <p><strong>ID Number</strong></p>
                    </td>
                    <td width="25%">
                        <p><strong>State/Country of Issuance</strong></p>
                    </td>
                    <td width="25%">
                        <p><strong>Expiration Date</strong></p>
                    </td>
                </tr>
                <tr>
                    <td>{{ data.senderPrimaryDocumentType }}</td>
                    <td>{{ data.senderPrimaryDocumentNumber }}</td>
                    <td>{{ data.senderPrimaryDocumentIssue }}</td>
                    <td>{{ data.senderPrimaryDocumentExpiration }}</td>
                </tr>
                <tr>
                    <td>
                        <p><strong>Secondary ID Type</strong></p>
                    </td>
                    <td>
                        <p><strong>D Number</strong></p>
                    </td>
                    <td>
                        <p><strong>State/Country of Issuance</strong></p>
                    </td>
                    <td>
                        <p><strong>Expiration Date</strong></p>
                    </td>
                </tr>
                <tr>
                    <td>{{ data.senderSecondaryDocumentType }}</td>
                    <td>{{ data.senderSecondaryDocumentNumber }}</td>
                    <td>{{ data.senderSecondaryDocumentIssue }}</td>
                    <td>{{ data.senderSecondaryDocumentExpiration }}</td>
                </tr>
            </table>
            <br />
            <br />
            <br />
            <table width="1000" border="0" align="center" cellpadding="0" cellspacing="0">
                <tr></tr>
            </table>
            <span style="text-align: center" colspan="4"></span>
            <table width="1000" border="0" align="center" cellpadding="0" cellspacing="0" class="tbTablesB">
                <tr>
                    <td colspan="4" style="text-align: center"><strong>ADDITIONAL INFORMATION</strong></td>
                </tr>
                <tr>
                    <td width="25%">
                        <p><strong>Source of Funds</strong></p>
                    </td>
                    <td width="25%">
                        <p><strong>Sender's Occupation</strong></p>
                    </td>
                    <td width="25%">
                        <p><strong>Purpose of Transaction</strong></p>
                    </td>
                    <td width="25%">
                        <p><strong>Sender's Relationship</strong></p>
                        <p><strong>To Beneficiary</strong></p>
                    </td>
                </tr>
                <tr>
                    <td>{{ data.sourceOfFunds }}</td>
                    <td>{{ data.occupation }}</td>
                    <td>{{ data.purposeOfTransaction }}</td>
                    <td>{{ data.senderRelationBeneficiary }}</td>
                </tr>
            </table>
            <br />
            <br />
            <br />
            <br />
            <table width="1000" border="0" align="center" cellpadding="0" cellspacing="0">
                <tr>
                    <td width="15%">&nbsp;</td>
                    <td width="794" style="text-align: center">
                        <strong>CLIENT CERTIFICATION</strong><br />
                        <br />
                        <br />
                        <br />
                    </td>
                    <td width="15%">&nbsp;</td>
                </tr>
                <tr>
                    <td width="15%">&nbsp;</td>
                    <td style="text-align: justify">
                        <strong>ATTENTION CUSTOMER:</strong> BY USING THESE MONEY TRANSFER SERVICES, YOU HEREBY ACKNOWLEDGE AND AFFIRM THAT ALL PERSONAL INFORMATION PROVIDED BY YOU AS SET ABOVE IS COMPLETELY TRUTHFUL AND ACCURATE/ USANDO ESTES SERVIÇOS DE TRANSFERÊNCIA DE DINHEIRO, VOCÊ RECONHECE E AFIRMA QUE TODAS AS INFORMAÇÕES PESSOAIS FORNECIDAS POR VOCÊ CONFORME ESTABELECIDO ACIMA, SEJA VERDADEIRAS E CORRETAS/AL USAR ESTOS SERVICIOS DE TRANFERENCIA TRANSFERENCIA DE DINERO, USTED RECONOCE Y AFIRMA QUE TODA LA INFORMACIÓN PERSONAL PROPORCIONADA POR USTED ESTABLECIDA ARRIBA ES COMPLETAMENTE VERDADERA Y PRECISA.
                        <br />
                        <br />
                    </td>
                    <td width="15%">&nbsp;</td>
                </tr>
                <tr>
                    <td width="15%">&nbsp;</td>
                    <td style="text-align: justify">YOU ALSO HEREBY ACKNOWLEDGE AND AFFIRM THAT YOU ARE NOT CONDUCTING THIS TRANSACTION ON BEHALF OF ANOTHER PERSON/VOCÊ TAMBÉM RECONHECE E AFIRMA QUE VOCÊ NÃO ESTÁ CONDUZINDO ESTA TRANSAÇÃO EM NOME DE OUTRA PESSOA/TAMBIÉN RECONOCE Y AFIRMA QUE NO REALIZA ESTA TRANSACCIÓN EN NOMBRE DE OTRA PERSONA.</td>
                    <td width="15%">&nbsp;</td>
                </tr>
                <tr>
                    <td>&nbsp;</td>
                    <td>&nbsp;</td>
                    <td>&nbsp;</td>
                </tr>
                <tr>
                    <td width="15%">&nbsp;</td>
                    <td>
                        <br />
                        <br />
                    </td>
                    <td width="15%">&nbsp;</td>
                </tr>
            </table>
        </body>
    </html>
</template>

<script>
import axios from 'axios';

export default {
    name: 'ComplianceForm',

    props: {
        orderId: Number,
    },
    data() {
        return {
            data: null,
            permissions: null,
            error: null,
        };
    },
    mounted() {
        this.load();
        this.getPermissions();
    },
    methods: {
        load() {
            this.loading = true;
            axios
                .get(`/niloagent/order/${this.orderId}/detail/complianceform`)
                .then((response) => {
                    this.data = response.data;
                })
                .catch((error) => {
                    this.data = null;
                    this.$toast.error(error.response.data.Message);
                })
                .finally(() => {
                    this.loading = false;
                });
        },
        reload() {
            this.load();
            this.getPermissions();
        },
        getPermissions() {
            axios
                .get(`/niloagent/order/${this.orderId}/detail/permissions`)
                .then((response) => {
                    this.permissions = response.data;
                })
                .catch((error) => {
                    this.$toast.error(error.response.data.Message);
                });
        },
        notImplemented() {
            this.$toast.error('This functionality was not implemented yet');
        },
    },
};
</script>

<style id="less:concepts-less-css-style" type="text/css" lang="scss" scoped>
.tbTablesB {
    border: thin solid #000000;
    text-align: center;
}
.tbTablesB td,
table th {
    border: 1px solid black;
    height: 50px;
}
@media print {
    .no-print,
    .no-print * {
        display: none !important;
    }
}
</style>
